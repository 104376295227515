<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax class="page-header-image" style="background-image:url('img/bg32.jpg')"></parallax>
      <div class="container">
        <h1 class="title">Bize Ulaşın</h1>
      </div>
    </div>
    <div class="main">
      <div class="contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-5 ml-auto mr-auto">
              <h2 class="title">Mesajınızı Bize İletin</h2>
              <p class="description">
                Hizmetlerimiz hakkında bilgi almak için mesaj gönderebilirsiniz.
                <br />
                <br />
              </p>
              <form id="contact-form" ref="form">
                <label>İsminiz</label>
                <fg-input
                 name = "name"
                  class="input-lg"
                  placeholder="İsim..."
                  v-model="form.firstName"
                  addon-left-icon="now-ui-icons users_circle-08"
                ></fg-input>
                <label>E-Posta</label>
                <fg-input
                name = "email"
                  class="input-lg"
                  placeholder="E-Posta..."
                  v-model="form.email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                ></fg-input>
                <label>Telefon</label>
                <fg-input
                name = "phone"
                  class="input-lg"
                  placeholder="Telefon..."
                  v-model="form.mobile"
                  addon-left-icon="now-ui-icons tech_mobile"
                ></fg-input>
                <div class="textarea-container"></div>
                <div class="form-group">
                  <label>Mesajınız</label>
                  <textarea
                    name="message"
                    id="message"
                    rows="6"
                    class="form-control"
                    v-model="form.message"
                    placeholder="Mesajınız..."
                  ></textarea>
                </div>
                <div class="submit text-center">
                  <n-button
                    type="button"
                    class="btn-round btn-primary"
                    v-on:click="sendEmail"
                  >İletişime Geç</n-button>
                </div>
                <alert type="success" dismisible v-show="form.messagesuccess">
                  <div class="alert-icon">
                    <i class="now-ui-icons ui-2_like"></i>
                  </div>
                  <strong>Mesajınız Gönderildi!</strong>
                </alert>
                <alert type="danger" dismisible v-show="form.messageerror">
                  <div class="alert-icon">
                    <i class="now-ui-icons objects_support-17"></i>
                  </div>
                  <strong>Mesajınız iletilemedi!</strong> Daha sonra tekrar deneyin.
                </alert>
              </form>
            </div>
            <div class="col-md-5 ml-auto mr-auto">
              <div class="map">
                <component :is="form.activeComponent" />
              </div>
              <div class="info mt-5 info-horizontal">
                <div class="icon icon-primary">
                  <i class="now-ui-icons location_pin"></i>
                </div>
                <div class="description">
                  <h4 class="info-title">Adresimiz</h4>
                  <p>
                    Sancaktepe Mahallesi 
                    <br />Sarıkamış caddesi 17/A
                    <br />Keçiören/Ankara
                  </p>
                </div>
              </div>
              <div class="info info-horizontal">
                <div class="icon icon-primary">
                  <i class="now-ui-icons tech_mobile"></i>
                </div>
                <div class="description">
                  <h4 class="info-title">Telefon</h4>
                  <p>
                    Serkan YILMAZ
                    <br /><a href="tel:+905324532533">532 4 532 533</a> 
                    <br /><a href="tel:+903123306606">312 330 66 06</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput,Alert } from "@/components";
import LeafletMap from "./components/LeafletMap";
import emailjs from "emailjs-com";
export default {
  name: "contact",
  bodyClass: "contact-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    LeafletMap,
    Alert
  },
  mounted: function() {
    this.$nextTick(function() {
      this.form.activeComponent = "LeafletMap";
    });
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
        mobile: "",
        activeComponent: "",
        messagesuccess: false,
        messageerror: false
      }
    };
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "service_ns640s8",
          "template_2924609",
          this.$refs.form,
          "user_vNtkG8KTN8J4VcAIYIxuO",
          {
            name: this.form.firstName,
            email: this.form.email,
            message: this.form.message,
            mobile : this.form.mobile
          }
        );
        this.form.messagesuccess = true;
      } catch (error) {
        this.form.messageerror = true;
      }

      this.form.firstName = "";
      this.form.email = "";
      this.form.message = "";
      this.form.mobile = "";
    }
  }
};
</script>
<style></style>