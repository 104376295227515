<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg6.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Kaanlar İklimlendirme</h1>
          <div class="text-center">
            <a
              href="https://www.facebook.com/kaanlar.iklimlendirme.9/about"
              class="btn btn-primary btn-icon btn-round"
            >
              <i class="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://www.instagram.com/kaanlar_iklimlendirme/"
              class="btn btn-primary btn-icon btn-round"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Biz Kimiz?</h2>
            <h5 class="description">
              Hedefimiz, sizlerin ihtiyaç, talep ve memnuniyetleriniz
              doğrultusunda en iyi hizmeti sunmaktır. Split, Multi Split ve VRF
              Klima sektöründe kaliteli hizmet sağlamak, ekonomik çözümler
              üretmek, en üst seviyede müşteri memnuniyetini hedefleyen
              çözümleri geliştirmek amacı ile çalışmalarını Ankara merkez
              ofisinde sürdürmekteyiz.
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-success icon-circle">
                <i class="now-ui-icons objects_globe"></i>
              </div>
              <h4 class="info-title">Keşif</h4>
              <p class="description">
                Teknik gerekliliklerin ve müşteri beklentilerinin anlaşılması için keşif hizmeti sunulmaktadır.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-info icon-circle">
                <i class="now-ui-icons education_atom"></i>
              </div>
              <h4 class="info-title">Projelendirme</h4>
              <p class="description">
                Konusunda uzman mühendis kadromuzla mekanik tesisat projelerini planlama hizmeti sunulmaktadır.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info info-hover">
              <div class="icon icon-primary icon-circle">
                <i class="now-ui-icons tech_watch-time"></i>
              </div>
              <h4 class="info-title">Satış & Montaj</h4>
              <p class="description">
                Konusunda uzman teknik kadromuz ile cihaz seçimi, fiyatlandırma ve cihaz montajı hizmeti sunulmaktadır. 
              </p>
            </div>
          </div>
        </div>
        <!-- <carousel-section></carousel-section> -->
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <h2 class="title text-center">Hizmetlerimiz</h2>
          </div>
          <tabs
            pills
            class="nav-align-center"
            tab-content-classes="gallery"
            tab-nav-classes="nav-pills-just-icons"
            type="primary"
          >
            <tab-pane title="Projeler">
              <i
                slot="label"
                class="now-ui-icons design-2_ruler-pencil"
                title="Projeler"
              ></i>
              <div class="col-md-10 ml-auto mr-auto">
                <h4 class="text-center">
                  <b>Projeler</b>
                </h4>
                <div class="row collections">
                  <div class="col-md-6">
                    <div class="card card-plain card-profile">
                      <div class="card-image">
                        <img src="img/bg1.jpg" class="img img-raised rounded" />
                      </div>
                      <div class="card-body">
                        <h4 class="card-title">Oto Moda Gross</h4>
                      </div>
                    </div>
                    <div class="card card-plain card-profile">
                      <div class="card-image">
                        <img src="img/bg3.jpg" class="img img-raised rounded" />
                      </div>
                      <div class="card-body">
                        <h4 class="card-title">Kahveci HACIBABA</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card card-plain card-profile">
                      <div class="card-image">
                        <img src="img/bg4.jpg" class="img img-raised rounded" />
                      </div>
                      <div class="card-body">
                        <h4 class="card-title">Öz Gıda İş Sendikası</h4>
                      </div>
                    </div>
                    <div class="card card-plain card-profile">
                      <div class="card-image">
                        <img src="img/bg5.jpg" class="img img-raised rounded" />
                      </div>
                      <div class="card-body">
                        <h4 class="card-title"></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane title="Hizmetlerimiz">
              <i
                slot="label"
                class="now-ui-icons education_paper"
                title="Hizmetlerimiz"
              ></i>
              <div class="col-md-10 ml-auto mr-auto">
                <h4 class="text-center">
                  <b>Hizmetlerimiz</b>
                </h4>
                <div class="row collections">
                  <div class="col-md-6">
                    <div class="card card-plain card-profile">
                      <div class="card-image">
                        <img src="img/Vrf.png" class="img img-raised rounded" />
                      </div>
                      <div class="card-body">
                        <h4 class="card-title">VRF Klima</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card card-plain card-profile">
                      <div class="card-image">
                        <img
                          src="img/multisplitklima.jpg"
                          class="img img-raised rounded"
                        />
                      </div>
                      <div class="card-body">
                        <h4 class="card-title">Split / Multi Split Klima</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane title="Referanslar">
              <i
                slot="label"
                class="now-ui-icons ui-2_settings-90"
                title="Referanslar"
              ></i>

              <div class="col-md-10 ml-auto mr-auto">
                <h4 class="text-center">
                  <b>Referanslar</b>
                </h4>
                <div class="row collections">
                  <div class="col-md-6">
                    <img src="img/midea.png" alt class="img-raised" />
                    <img src="img/mitsubishi.png" alt class="img-raised" />
                  </div>
                  <div class="col-md-6">
                    <img src="img/systemair.png" alt class="img-raised" />
                    <img src="img/untes.png" class="img-raised" />
                  </div>
                  <div class="col-md-6">
                    <img src="img/Haier.png" alt class="img-raised" />
                  </div>
                  <div class="col-md-6">
                    <img src="img/asal.jpg" alt class="img-raised" />
                  </div>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Referanslarımız</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <p class="category">Mitsubishi</p>
              <img
                style="max-width: 65%"
                alt="Rounded Image"
                class="rounded"
                data-src="img/mitsubishi.pngs"
                src="img/mitsubishi.png"
                lazy="loaded"
              />
            </div>
            <div class="col-md-4">
              <p class="category">Midea</p>
              <img
                style="max-width: 65%"
                alt="Rounded Image"
                class="rounded"
                data-src="img/mitsubishi.pngs"
                src="img/midea.png"
                lazy="loaded"
              />
            </div>
            <div class="col-md-4">
              <p class="category">Üntes</p>
              <img
                style="max-width: 65%"
                alt="Rounded Image"
                class="rounded"
                data-src="img/untes.png"
                src="img/untes.png"
                lazy="loaded"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p class="category">Systemair</p>
              <img
                style="max-width: 65%"
                alt="Rounded Image"
                class="rounded"
                data-src="img/systemair.png"
                src="img/systemair.png"
                lazy="loaded"
              />
            </div>
            <div class="col-md-4">
              <p class="category">Haier</p>
              <img
                style="max-width: 65%"
                alt="Rounded Image"
                class="rounded"
                data-src="img/haier.png"
                src="img/Haier.png"
                lazy="loaded"
              />
            </div>
            <div class="col-md-4">
              <p class="category">Asal</p>
              <img
                style="max-width: 65%"
                alt="Rounded Image"
                class="rounded"
                data-src="img/asal.jpg"
                src="img/asal.jpg"
                lazy="loaded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Bizimle çalışmak istermisiniz?</h2>
        <p class="description">Sizin projeleriniz bizler için çok önemli.</p>
        <form ref="form">
          <div class="row">
            <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
              <fg-input
                class="input-lg"
                placeholder="İsim..."
                v-model="form.firstName"
                addon-left-icon="now-ui-icons users_circle-08"
              ></fg-input>
              <fg-input
                class="input-lg"
                placeholder="E-Posta..."
                v-model="form.email"
                addon-left-icon="now-ui-icons ui-1_email-85"
              ></fg-input>
              <div class="textarea-container">
                <textarea
                  class="form-control"
                  name="name"
                  rows="4"
                  cols="80"
                  v-model="form.message"
                  placeholder="Mesajınız..."
                ></textarea>
              </div>
              <div class="send-button">
                <n-button
                  v-on:click="sendEmail"
                  type="primary"
                  round
                  block
                  size="lg"
                  >Mesaj Gönder</n-button
                >
              </div>
              <alert type="success" dismisible v-show="form.messagesuccess">
                <div class="alert-icon">
                  <i class="now-ui-icons ui-2_like"></i>
                </div>
                <strong>Mesajınız Gönderildi!</strong>
              </alert>
              <alert type="danger" dismisible v-show="form.messageerror">
                <div class="alert-icon">
                  <i class="now-ui-icons objects_support-17"></i>
                </div>
                <strong>Mesajınız iletilemedi!</strong> Daha sonra tekrar
                deneyin.
              </alert>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput, Alert, Tabs, TabPane } from "@/components";
import emailjs from "emailjs-com";
// import CarouselSection from "./components/CarouselSection";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    // CarouselSection,
    Alert,
    Tabs,
    TabPane,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
        messagesuccess: false,
        messageerror: false,
      },
    };
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "service_ns640s8",
          "template_2924609",
          this.$refs.form,
          "user_vNtkG8KTN8J4VcAIYIxuO",
          {
            name: this.form.firstName,
            email: this.form.email,
            message: this.form.message,
          }
        );
        this.form.messagesuccess = true;
      } catch (error) {
        this.form.messageerror = true;
      }

      this.form.firstName = "";
      this.form.email = "";
      this.form.message = "";
    },
  },
};
</script>
<style></style>
